import React, { Component } from 'react';
import * as firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as moment from 'moment';
import logo from './HERO.png'

class App extends Component {
  constructor() {
    super();

    this.state = {
      days: [],
      user: JSON.parse(localStorage.getItem('user')),
      date: moment().format('ddd MMMM DD'),
      clockInTime: localStorage.getItem('clockInTime'),
      clockOutTime: localStorage.getItem('clockOutTime'),
      shift_end: "",
      showPopUp: false
    }
  }


  uiConfig = {
    signInFlow: "popup",
    signInOptions : [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {

        let isNewUser = authResult.additionalUserInfo.isNewUser;

        if(isNewUser) {
          let user = authResult.user;

          firebase.database().ref('users/' + user.uid).set({
            uid: user.uid,
            displayName: user.displayName,
            shift: "",
            shift_verified: false
          }, (err) => {
            if(err) {
              console.log("An error occured: " + err)
            } else {
              console.log("A new user has been added!")
            }
          });
        }
      }
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  handleShiftVerification = () => {
    firebase.database().ref('users').child(this.state.user.uid).update({
      shift: this.state.shift_end,
      shift_verified: true
    }, (err) => {
      if(err) {
        console.log(err)
      }
    });

    firebase.database().ref('timesheets').child(this.state.user.uid).push({
      date: 'Mon Jun 17',
      clockInTime: '8:00 AM',
      clockOutTime: this.state.shift_end
    });

    firebase.database().ref('timesheets').child(this.state.user.uid).push({
      date: 'Tue Jun 18',
      clockInTime: '8:00 AM',
      clockOutTime: this.state.shift_end
    });

  }

  handleClockIn = () => {

    // after state is set, set date and clock in time for tracker object
    const tracker = {
      date: this.state.date
    };

    // stringify tracker object and set to localStorage
    localStorage.setItem('tracker', JSON.stringify(tracker));

    // get clock in time from localStorage and set state
    let formattedClockInDate = moment().format('LT');
    this.setState({ clockInTime: formattedClockInDate });

    localStorage.setItem('clockInTime', formattedClockInDate);
  }

  handleClockOut = () => {

    // check before clock out
    let readyToClockOut = window.confirm(`It is currently ${moment().format('LT')} are you sure you want to clock out?`);

    if(readyToClockOut) {

      // parse localStorage tracker object and add clock out time to tracker object
      let tracker = JSON.parse(localStorage.getItem('tracker'));
      tracker.clockOutTime = moment();
      
      localStorage.setItem('tracker', JSON.stringify(tracker));

      // get clock out time and set to state
      let formattedClockOutDate = moment(localStorage.getItem('tracker').clockOutTime).format('LT');
      this.setState({ clockOutTime: formattedClockOutDate });

      localStorage.setItem('clockOutTime', formattedClockOutDate);
    }
  }

  handlePrint = () => {
    window.print();
  }

  handleRefresh = () => {
    window.location.reload();
  }

  componentDidMount() {

    // localStorage.clear();

    setTimeout( function() {
      this.setState({ showPopUp: true });
    }
    .bind(this), 43200000 //refresh after 12 hours to ensure user clock out
    );

    const tracker = JSON.parse(localStorage.getItem('tracker'));
    const today = moment().format('ddd MMMM DD');

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {

          firebase.database().ref('timesheets').child(user.uid).orderByKey().limitToLast(14)
            .once('value', (snapshot) => {
              let reports = snapshot.val();
              let newState = [];

              for(let report in reports) {
                newState.push({
                  id: report,
                  date: reports[report].date,
                  clockIn: reports[report].clockInTime,
                  clockOut: reports[report].clockOutTime
                });
              }
              
              // console.log(newState, this.state.days);
              this.setState({ days: newState });
          });
          
          firebase.database().ref('users').child(user.uid)
            .on('value', (snapshot) => {

              const user = snapshot.val();
              
              localStorage.setItem('user', JSON.stringify(user));
              
              this.setState({ user : user });
            });

        },() => {

          localStorage.removeItem('user');
          this.setState({ user: null });
        }
    );

    if(tracker) {
      if(today !== tracker.date && tracker.clockOutTime == null) {

        firebase.database().ref('timesheets').child(this.state.user.uid).push({
          date: tracker.date,
          clockInTime: this.state.clockInTime,
          clockOutTime: this.state.user.shift
        });

        localStorage.clear();

      } else if(today !== tracker.date && tracker.clockOutTime) {

        //let diff = moment.duration(tracker.clockOutTime.diff(tracker.clockInTime));

        //let hours = diff.asHours();

        firebase.database().ref('timesheets').child(this.state.user.uid).push({
          date: tracker.date,
          clockInTime: this.state.clockInTime,
          clockOutTime: this.state.clockOutTime
          //hours: Math.floor(hours)
        });

        localStorage.clear();
      }
    }
  }

  componentWillUnmount() {

    clearTimeout();
    
    this.unregisterAuthObserver();

    firebase.database().ref('users').off();
    firebase.database().ref('timesheets').off();
  }

  render() {
    return (
      <div>

      { this.state.showPopUp ? 
        <div className="overlay">
          <div className="popup">
            <p className="text-muted">Your session has timed out due to inactivity. Click refresh below to continue.</p>
          
            <div className="modal-footer">
              <button onClick={this.handleRefresh} className="btn btn-secondary btn-small">Refresh</button>
            </div>

          </div>
        </div> : null }

      { !this.state.user ?

        <div id="wrapper" className="container text-center">
          <div id="signin">
            <img src={logo} alt="HERO logo" />
            <p>Please sign-in with your @herohousing.org account</p>
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
          </div>
        </div>

        :

        <div className="container">

          {this.state.user.shift_verified ? null : <div className="alert alert-danger" role="alert">Your scheduled shift must be verified before you can log in. <button className="link-btn" data-toggle="modal" data-target="#verificationModal">Verify</button> </div>}
          
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <span className="navbar-brand">{this.state.user.displayName}</span>

            <div className="collapse navbar-collapse d-flex flex-row-reverse" id="navbarSupportedContent">
              <button className="btn btn-outline-info" onClick={this.handlePrint}>Print</button>
              { !this.state.clockInTime ? <button disabled={!this.state.user.shift_verified} onClick={this.handleClockIn} className="btn btn-success">Clock In</button> : <button disabled={this.state.clockOutTime } onClick={this.handleClockOut} className="btn btn-danger">Clock Out</button> }
            </div>

          </nav>

          <h4 className="print">{this.state.user.displayName}</h4>

          <table id="userClockIn" className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Clock In</th>
                <th scope="col">Clock Out</th>
              </tr>
            </thead>

            <tbody>
              <tr className="hide">
                <th scope="row">{this.state.date}</th>
                <th>{this.state.clockInTime ? this.state.clockInTime : "0:00"}</th>
                <th>{this.state.clockOutTime ? this.state.clockOutTime : "0:00"}</th>
              </tr>

              { this.state.days ? this.state.days.map((day) => {

                return(
                  <tr key={day.id}>
                    <th scope="row">{day.date}</th>
                    <th>{day.clockIn}</th>
                    <th>{day.clockOut}</th>
                  </tr>
                )
              }): null }
            </tbody>
          </table>

          <div className="modal fade" data-backdrop="static" id="verificationModal" tabIndex="-1" role="dialog" aria-labelledby="verificationModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="verificationModalLabel">My Daily Shift</h5>
                </div>
                <div className="modal-body">
                  
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">

                      <div className="form-group col-md-6">
                        <label htmlFor="shift_start">Shift Start</label>
                        <select className="form-control" id="shift_start" disabled={this.state.user.shift_verified} required>
                          <option value="">0:00</option>
                          <option value="7:00 AM">7:00 AM</option>
                          <option value="8:00 AM">8:00 AM</option>
                          <option value="9:00 AM">9:00 AM</option>
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="shift_end">Shift End</label>
                        <select name="shift_end" className="form-control" id="shift_end" disabled={this.state.user.shift_verified} onChange={this.handleChange} value={this.state.shift_end} required>
                          <option value="">0:00</option>
                          <option value="2:00 PM">2:00 PM</option>
                          <option value="2:30 PM">2:30 PM</option>
                          <option value="3:00 PM">3:00 PM</option>
                          <option value="3:00 PM">3:30 PM</option>
                          <option value="4:00 PM">4:00 PM</option>
                          <option value="4:30 PM">4:30 PM</option>
                        </select>
                      </div>

                    </div>
                  </form>

                </div>

                <div className="modal-footer">
                  <button onClick={this.handleShiftVerification} data-dismiss="modal" disabled={!this.state.shift_end} type="button" className="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>

        </div>

      }
      </div>
    );
  }
}

export default App;